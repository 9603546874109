<template>
  <div class="login-background">
    <div class="recover-password-view">
      <div class="text-center">
        <div class="recover-password-header mb-10">
          {{ $t('views.recover_password.title') }}
        </div>
        <div>
          <span class="login-info">{{ $t('views.recover_password.info') }}</span>
        </div>
      </div>
      <div class="form-recover-password-container">
        <recover-password-form @submited="doRecoverPassword" />
      </div>
    </div>
  </div>
</template>

<script>
import RecoverPasswordForm from '@/components/forms/recoverPassword/RecoverPasswordForm.vue';

export default {
  name: 'RecoverPassword',
  components: {
    RecoverPasswordForm,
  },
  methods: {
    doRecoverPassword(params) {
      this.$store.dispatch('initLoading');
      this.$store.dispatch('recoverPassword', params);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/forms/recover_password.scss" scoped/>
