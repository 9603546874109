<template>
  <div class="recover-password">
    <v-form
      @keyup.native.enter="executeRecoverPassword"
    >
      <ValidationProvider
        v-slot="{errors}"
        name="email"
        rules="required|email"
      >
        <v-text-field
          v-model="email"
          :label="$t('components.labels.email')"
          type="email"
          outlined
          color="accent"
          class="email-field"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div>
        <router-link
          class="text-right float-right"
          to="/login"
        >
          {{ $t('components.recover_password.login_btn') }}
        </router-link>
      </div>
      <div class="text-xs-center">
        <v-btn
          class="m-btn"
          block
          large
          color="accent"
          @click="executeRecoverPassword"
        >
          {{ $t('components.recover_password.btn') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'RecoverPasswordForm',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    executeRecoverPassword() {
      this.$emit('submited', { email: this.email });
    },
  },
};
</script>

<style lang="scss" scoped>
.recover-password {
  .m-btn {
    display: block;
    position: relative;
    top: 60px;
    background-color: $color_primary;
  }
  .v-btn:not(.v-btn--round).v-size--large {
    height: 56px;
  }
}
</style>
